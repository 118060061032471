<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined class="primary--border">
          <v-card-title>
            <span class="mt-n7">Optional Groups</span>
            <v-spacer></v-spacer>
            <v-flex xs2>
              <v-select
                :loading="loadingGrade"
                :disabled="grades.length < 1"
                @change="getSections(), getSubjects(), getOptionalList()"
                :items="grades"
                outlined
                dense
                class="pa-0"
                label="Select grade"
                v-model="grade"
              />
            </v-flex>
            <v-flex xs2>
              <v-select
                :loading="loadingSection"
                :disabled="sections.length < 1"
                :items="sections"
                @change="getOptionalList()"
                class="pa-0"
                outlined
                dense
                label="Select section"
                v-model="section"
              />
            </v-flex>
          </v-card-title>

          <v-container class="subject_list_group">
            <h4
              v-if="selectedGrades && subjectList.length > 0"
              style="color:#777;font-weight: normal"
            >
              Subject List for
              <strong style="color:#444"
                >{{ selectedGrades }}
                <span v-if="selectedSection"
                  >({{ selectedSection }})</span
                ></strong
              >&nbsp;
              <small v-if="hasOptionalSubjects"
                >(Click to select optional subject.)</small
              >
            </h4>
            <span class="mt-2" v-for="(subject, key) in subjectList" :key="key">
              <v-chip class="mr-2 mb-2" v-if="subject.sub_type === 'comp'">
                {{ subject.name }}
              </v-chip>
              &nbsp;
              <v-chip
                class="mr-2 mt-2"
                @click="selectOptSubject(subject)"
                v-else-if="subject.sub_type === 'opt'"
                color="primary"
                text-color="white"
              >
                <v-avatar v-if="selectedOptSubjects.indexOf(subject) > -1">
                  <v-icon>check_circle</v-icon>
                </v-avatar>
                {{ subject.name }} </v-chip
              >&nbsp;
            </span>

            <br />
            <br />
            <v-alert
              v-if="subjectList.length > 0 && !hasOptionalSubjects"
              :value="true"
              color="warning"
              icon="priority_high"
              outlined
            >
              <strong>Warning!!</strong> No optional subjects has been specified
              for this grade <strong>({{ selectedGrades }})</strong>
              yet. Please specify some optional subjects and try again.
            </v-alert>

            <v-alert
              v-if="selectedGrades && subjectList.length < 1"
              :value="true"
              color="warning"
              icon="priority_high"
              outlined
            >
              <strong>Warning!!</strong> No subjects has been assigned for this
              grade <strong>({{ selectedGrades }})</strong>
              yet. Please assign some subjects and try again.
            </v-alert>
          </v-container>

          <v-container>
            <template>
              <v-layout row wrap>
                <v-flex xs6>
                  <v-card v-if="hasOptionalSubjects && allStudent.length">
                    <v-card-title>
                      <h4>Student List</h4>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list dense>
                      <template>
                        <v-data-table
                          :options.sync="pagination"
                          v-model="selected"
                          :headers="headers"
                          :items="allStudent"
                          show-all
                          item-key="name"
                          show-select
                          hide-default-footer
                          class="elevation-1"
                        >
                        </v-data-table>

                        <!--                                                <v-data-table-->
                        <!--                                                        v-model="selected"-->
                        <!--                                                        :headers="headers"-->
                        <!--                                                        :items="allStudent"-->
                        <!--                                                        hide-default-footer-->
                        <!--                                                        show-all-->
                        <!--                                                        item-key="name"-->
                        <!--                                                        class="elevation-1">-->
                        <!--                                                    <template v-slot:headers="props">-->
                        <!--                                                        <tr>-->
                        <!--                                                            <th>-->
                        <!--                                                                <v-checkbox-->
                        <!--                                                                        :input-value="props.all"-->
                        <!--                                                                        :indeterminate="props.indeterminate"-->
                        <!--                                                                        primary-->
                        <!--                                                                        hide-details-->
                        <!--                                                                        @click.stop="toggleAll"></v-checkbox>-->
                        <!--                                                            </th>-->
                        <!--                                                            <th :align="header.align"-->
                        <!--                                                                v-for="header in props.headers"-->
                        <!--                                                                :key="header.text"-->
                        <!--                                                                :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']">-->
                        <!--                                                                {{ header.text }}-->
                        <!--                                                            </th>-->
                        <!--                                                        </tr>-->
                        <!--                                                    </template>-->
                        <!--                                                    <template v-slot:items="props">-->
                        <!--                                                        <tr :active="props.selected"-->
                        <!--                                                            @click="props.selected = !props.selected">-->
                        <!--                                                            <td>-->
                        <!--                                                                <v-checkbox-->
                        <!--                                                                        :input-value="props.selected"-->
                        <!--                                                                        primary-->
                        <!--                                                                        hide-details></v-checkbox>-->
                        <!--                                                            </td>-->
                        <!--                                                            <td>{{ props.item.name }}</td>-->
                        <!--                                                            <td class="text-xs-right">{{ props.item.roll }}</td>-->
                        <!--                                                            <td></td>-->
                        <!--                                                        </tr>-->
                        <!--                                                    </template>-->
                        <!--                                                </v-data-table>-->
                      </template>
                      <v-btn
                        v-if="
                          selected.length > 0 && selectedOptSubjects.length > 0
                        "
                        @click="updateOptionalList"
                        color="green"
                        dark
                        big
                        absolute
                        bottom
                        right
                        fab
                      >
                        <v-icon>check</v-icon>
                      </v-btn>
                    </v-list>
                  </v-card>
                </v-flex>
                <v-flex xs6>
                  <v-card v-if="optionalStudentList.length || selected.length">
                    <v-card-title>
                      <h4>
                        <span v-if="selected.length > 0"
                          >Grouping {{ selected.length }}
                          {{ selected.length > 1 ? "student's" : "student" }} to
                        </span>
                        {{
                          selectedSubjects
                            ? selectedSubjects + " " + " Group"
                            : ""
                        }}
                      </h4>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list dense>
                      <template>
                        <v-data-table
                          v-model="selected"
                          :headers="optionalHeaders"
                          :items="optionalStudentList"
                          :options.sync="pagination"
                          hide-default-footer
                          item-key="name"
                          class="elevation-1"
                        >
                          <template v-slot:item="{ index, item }">
                            <tr
                              :active="selected"
                              @click="selected = !selected"
                            >
                              <td>{{ index + 1 }}</td>
                              <td class="optional-subject">
                                <strong>{{ item.name }}</strong> <br />
                                <small
                                  v-for="(subject, key) in item.subject.split(
                                    ','
                                  )"
                                  :key="key"
                                >
                                  <v-chip
                                    @click="deleteOptionalSubject(subject)"
                                    close
                                    small
                                    tiny
                                  >
                                    {{ subject.replace(/^\d*:/, "") }}
                                  </v-chip>
                                </small>
                              </td>
                              <td>{{ item.roll }}</td>
                            </tr>
                          </template>
                        </v-data-table>

                        <!--                                                <v-data-table-->
                        <!--                                                        :headers="optionalHeaders"-->
                        <!--                                                        :items="optionalStudentList"-->
                        <!--                                                        :options.sync="pagination"-->
                        <!--                                                        hide-default-footer-->
                        <!--                                                        item-key="name"-->
                        <!--                                                        class="elevation-1">-->
                        <!--                                                    <template v-slot:items="props">-->
                        <!--                                                        <tr :active="selected"-->
                        <!--                                                            @click="props.selected = !props.selected">-->
                        <!--                                                            <td>{{props.index+1}}</td>-->
                        <!--                                                            <td class="optional-subject">-->
                        <!--                                                                <strong>{{ props.item.name }}</strong> <br>-->
                        <!--                                                                <small v-for="(subject,key) in props.item.subject.split(',')"-->
                        <!--                                                                    :key="key">-->
                        <!--                                                                    <v-chip @input="deleteOptionalSubject(subject)"-->
                        <!--                                                                            close small tiny>-->
                        <!--                                                                        {{subject.replace(/^\d*:/,'')}}-->
                        <!--                                                                    </v-chip>-->
                        <!--                                                                </small>-->
                        <!--                                                            </td>-->
                        <!--                                                            <td>{{props.item.roll}}</td>-->

                        <!--                                                        </tr>-->
                        <!--                                                    </template>-->
                        <!--                                                </v-data-table>-->
                      </template>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
            </template>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deleteConfirm" persistent max-width="400">
      <v-card>
        <v-card-title class="title pa-3 red white--text">
          <v-icon class="mr-2 white--text">warning</v-icon>
          Please, wait!
        </v-card-title>
        <v-card-text class="ma-0">
          <h3>{{ "Are you sure you want to unassign this subject" }}</h3>
          <v-divider></v-divider>

          <p
            style="line-height: 1.4;font-size: 13px;color:#555;margin-top: 10px;margin-bottom: 0;"
          >
            Un assigning a optional subject may directly affect on Mark Sheet,
            Routine etc and may harm your student's data. Please make sure you
            are fully aware what you are doing else you can contact our support.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            small
            @click="
              (dialog = false),
                (deleteConfirm = false),
                (deleteOptionalSubjectId = null)
            "
            >No
          </v-btn>
          <v-btn
            color="error"
            outlined
            small
            @click="(dialog = false), deleteOptionalSubjectConfirm()"
            >Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { getPropertyFromArrayObject } from "@/library/helpers";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],

  data: () => ({
    deleteConfirm: false,
    deleteOptionalSubjectId: null,
    form: new Form({}, "/api/grades"),
    grades: [],
    grade: "",
    section: "",
    sections: [],
    loadingGrade: false,
    loadingSection: false,
    selected: [],
    subjectList: [],
    allStudent: [],
    selectedOptSubjects: [],
    optionalStudentList: [],
    headers: [
      { text: "Name", value: "name", align: "left", sortable: false },
      { text: "Roll", value: "roll", sortable: false, align: "right" },
      { text: "", value: "", sortable: false, align: "right" },
    ],
    optionalHeaders: [
      { text: "#", value: "id", align: "left", sortable: false },
      { text: "Name", value: "name", align: "left", sortable: false },
      { text: "Roll", value: "roll", align: "left", sortable: false },
    ],
    pagination: {
      itemsPerPage: 100,
      sortBy: "roll",
    },
  }),

  computed: {
    ...mapState(["batch"]),
    selectedGrades() {
      let $this = this;
      let grade = "";
      this.grades.map(function(data) {
        if ($this.grade === data.value) {
          grade = data.text;
        }
      });
      return grade;
    },
    selectedSection() {
      let $this = this;
      let section = "";
      this.sections.map(function(data) {
        if ($this.section === data.value) {
          section = data.text;
        }
      });
      return section;
    },
    selectedSubjects() {
      if (this.selectedOptSubjects.length > 0) {
        return getPropertyFromArrayObject(
          "name",
          this.selectedOptSubjects
        ).join(", ");
      }
      return "";
      // return this.selectedOptSubjects.join(', ')
    },
    studentListItems() {
      return [];
    },
    hasOptionalSubjects() {
      let flag = false;
      this.subjectList.map(function(sub) {
        if (sub.sub_type === "opt") {
          flag = true;
        }
      });
      return flag;
    },
  },
  mounted() {
    this.getGrades();
  },
  watch: {},
  methods: {
    getGrades() {
      this.sections = [];
      this.section = "";
      this.loadingGrade = true;
      this.$rest
        .get(
          "/api/grade-optional-subject?itemsPerPage=100&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally((err) => {
          this.loadingGrade = false;
        });
    },
    getSections() {
      this.section = "";
      this.sections = [];
      this.loadingSection = true;
      this.$rest
        .get("/api/section?batch=" + this.batchId + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.loadingSection = false;
        });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.allStudent.slice();
    },
    getOptionalList() {
      let subjectId = getPropertyFromArrayObject(
        "id",
        this.selectedOptSubjects
      );
      let subject = "";
      if (subjectId.length) subject = subjectId.join(",");

      this.$rest
        .get(
          "/api/subject-opt-group?itemsPerPage=25&grade=" +
            this.grade +
            "&section=" +
            this.section +
            "&subject=" +
            subject
        )
        .then(({ data }) => {
          this.allStudent = data.students;
          this.optionalStudentList = data.optionalSubjectStudents;
          let $this = this;
        });
    },
    getSubjects() {
      this.selectedOptSubjects = [];
      this.subjectList = [];
      this.$rest
        .get("/api/subject?itemsPerPage=25&sortBy=sub_type&grade=" + this.grade)
        .then(({ data }) => {
          this.subjectList = data.data.filter((data) => {
            if (data.exam === true) {
              return data;
            }
          });
        });
    },
    selectOptSubject(subject) {
      if (this.selectedOptSubjects.indexOf(subject) > -1) {
        this.selectedOptSubjects.splice(
          this.selectedOptSubjects.indexOf(subject),
          1
        );
      } else {
        this.selectedOptSubjects.push(subject);
      }
      this.getOptionalList();
    },
    updateOptionalList() {
      let subjectId = getPropertyFromArrayObject(
        "id",
        this.selectedOptSubjects
      );
      let subject = "";
      if (subjectId.length) subject = subjectId.join(",");

      this.$rest
        .post("api/subject-opt-group", {
          students: this.selected,
          subjects: subject,
        })
        .then(({ data }) => {
          this.$events.fire("notification", { message: data.message });
          this.getOptionalList();
        });
    },
    deleteOptionalSubject(subject) {
      // console.log(subject);
      if (!subject) return;
      // this.deleteOptionalSubjectId = subject.replace(/:\D*/, '');
      this.deleteConfirm = true;
      // console.log(subjectId);
    },
    deleteOptionalSubjectConfirm() {
      if (this.deleteOptionalSubjectId)
        this.$rest
          .delete("/api/subject-opt-group/" + this.deleteOptionalSubjectId)
          .then(({ data }) => {
            this.$events.fire("notification", { message: data.message });
            this.getOptionalList();
            this.deleteOptionalSubjectId = null;
            this.deleteConfirm = false;
          });
      else {
        this.$events.fire("notification", {
          message: "Something is wrong. Please contact support",
        });
        this.deleteConfirm = false;
      }
    },
  },
};
</script>
<style lang="scss">
.subject_list_group {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
  }
}

.optional-subject {
  .v-chip {
    margin-left: 0;
  }

  .v-chip--small {
    height: 20px !important;
    padding: 0 !important;
  }

  .v-chip--removable .v-chip__content {
    padding: 7px 0px 7px 5px !important;
  }

  .v-chip__content {
    font-size: 10px;
  }
}
</style>
